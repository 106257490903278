@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/eurostile");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Eurostile", sans-serif;
  scroll-behavior: smooth;
}

h1 {
  font-size: 88px;
  letter-spacing: -2%;
}

h2 {
  font-size: 68px;
  letter-spacing: -2%;
}

h3 {
  font-size: 48px;
  letter-spacing: -2%;
}
h4 {
  font-size: 40px;
  line-height: 48px;
  // letter-spacing: 0%;
}
h5 {
  font-size: 28px;
  // letter-spacing: 0%;
}
h6 {
  font-size: 24px;
  // letter-spacing: 0%;
}

.lg-bold {
  font-size: 20px;
}
.md-bold {
  font-size: 18px;
}
.sm-bold {
  font-size: 14px;
}

.btn-outline {
  background-color: rgba(255, 255, 255, 1);
  gap: 8px;
}
.bg-gradient {
  background: rgb(0, 130, 154);
  background: linear-gradient(
    180deg,
    rgba(0, 130, 154, 1) 0%,
    rgba(12, 89, 132, 1) 50%,
    rgba(22, 54, 114, 1) 100%
  );
}

.btn-outline-new {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 130, 154, 1);
  border-radius: 12px;
  height: 43px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.btn-outline-new:hover {
  background-color: rgba(97, 54, 110, 1);
  color: white;
}

.bg-gradient-new {
  background: rgb(0, 130, 154);
  background: linear-gradient(
    180deg,
    rgba(0, 130, 154, 0.66) 50%,
    rgba(97, 54, 110, 0.77)
  );
}

@media (max-width: 760px) {
  h4 {
    font-size: 30px;
    line-height: 38px;
    // letter-spacing: 0%;
  }
}